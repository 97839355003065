import React, { useEffect } from "react";
import { Globals } from "react-spring";
import { BaseLayout, useReduceMotion } from "gatsby-theme-jqi";
 
const Provider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  // Update these items to match the current site's IA
  const menuItems = [
    {
      title: "Research",
      url: "/research",
    },
    {
      title: "People",
      url: "/people",
    },
    {
      title: "News",
      url: "/news",
    },
    {
      title: "Publications",
      url: "/publications",
    },
    {
      title: "Theses",
      url: "/theses",
    },
  ] as JQI.MenuItem[];
  const headerProps = {
    logo: {
      src: "/logo_hafezi.svg", // Update this to whatever the logo is named in ./src/static/
      alt: "Joint Quantum Institute Research - Hafezi Group", // Update this to use the actual group name
      width: 387, // Set this to the actual width of the logo
      height: 66, // Set this to the actual height of the logo
    },
    menuItems,
  } as JQI.SiteHeaderProps;
  const footerProps = { menuItems } as JQI.SiteFooterProps;
  // Globally disables React Spring animations based on user's OS-level reduced motion preference.
  const prefersReducedMotion = useReduceMotion() as boolean;
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion]);
  return (
    <>
      <BaseLayout headerProps={headerProps} footerProps={footerProps}>
        {children}
      </BaseLayout>
    </>
  );
};

export default Provider;